<template>
  <div>
    <div class="row mb-3">
      <div class="col-sm-12">
        <div class="form-group row" style="margin: auto;">
          <label class="checkbox checkbox-success mr-4">
            <input
              id="checkBoxActivar"
              type="checkbox"
              v-model="activar"
              @change="saveActivarDesactivarUI"
              :disabled="isDisabled"
            />
            <span>Activar</span>
            <span class="checkmark"></span>
          </label>
          <div class="col-sm-2">
            <b-input-group>
              <input
                v-model="configUI.nombre"
                @keyup.enter="guardarParametroNombre"
                type="text"
                class="form-control"
                :placeholder="!configUI.nombre ? 'S/N': ''"
                :disabled="isDisabled"
              >
              <b-input-group-append>
                <button
                  class="btn btn-light px-2"
                  @click="guardarParametroNombre"
                  :disabled="isDisabled"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    fill="currentColor"
                    class="bi bi-floppy"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 2H9v3h2z"/>
                    <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16
                      2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0
                      0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5
                      0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0
                      0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5
                      7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0
                      .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5
                      0 0 0-.5.5z"
                    />
                  </svg>
                </button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div v-if="activar" class="row">
      <div class="col-sm-12">
        <b-table
          class="table table-striped table-hover"
          :fields="fields"
          :items="listaTabla"
          :busy="isLoadingTable"
        >
          <template #head(opciones)>
            <button
              class="btn btn-block btn-success"
              @click="openModalCategoria"
            >Nuevo</button>
          </template>
          <template #head(seguros)="head">
            <div v-b-popover.hover.top="popOverSeguro">
              {{head.label}}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-exclamation-circle-fill text-danger"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 4a.905.905 0 0 0-.9.995l.35
                 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4m.002 6a1 1 0 1 0
                 0 2 1 1 0 0 0 0-2"
                />
              </svg>
            </div>
          </template>
          <template #head(lugar)="head">
            <div v-b-popover.hover.top="popOverLugar">
              {{head.label}}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-exclamation-circle-fill text-danger"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 4a.905.905 0 0 0-.9.995l.35
                 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4m.002 6a1 1 0 1 0
                 0 2 1 1 0 0 0 0-2"
                />
              </svg>
            </div>
          </template>
          <template #cell(opciones)="items">
            <!-- <i
              class="nav-icon i-Pen-5 font-weight-bold text-success pr-3"
              style="cursor: pointer"
            ></i> -->
            <i
              @click="onClickEliminarIcon(items.item)"
              class="nav-icon i-Close-Window font-weight-bold text-danger"
              style="cursor: pointer"
            ></i>
          </template>
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
              <strong>Cargando...</strong>
            </div>
          </template>
          <template #cell(precio1)="items">
            {{getPrecio1(items.item.precios)}}
          </template>
          <template #cell(precio2)="items">
            {{getPrecio2(items.item.precios)}}
          </template>
          <template #cell(activo)="row">
            <button
              class="btn py-1"
              :class="row.item.activo? 'btn-success': 'btn-danger'"
              @click="row.item.activo = !row.item.activo;updateEstadoItem(row.item)"
            >
              {{row.item.activo? 'Activo':'Pausa'}}
            </button>
          </template>
        </b-table>
      </div>
    </div>
    <b-modal
      id="modal-eliminar"
      :title="'Seguro que quieres eliminar el Registro - N° '+selectedNro+'?'"
      hide-footer
    >
      <div class="row">
        <div class="col-sm-6">
          <button class="col-sm-12 btn btn-danger" @click="eliminarRegistro">
            Eliminar
          </button>
        </div>
        <div class="col-sm-6">
          <button class="col-sm-12 btn btn-outline-dark"
          @click="$bvModal.hide('modal-eliminar')">Cancelar</button>
        </div>
      </div>
    </b-modal>
    <ModalCategoria
      :titleModal="'Nuevo Tratamiento'"
      :listaServicios="listaServicios"
      :idParent="id"
      @onStoreService="getData"
    />
  </div>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import ModalCategoria from './modalCategoria.vue';

export default {
  name: 'TheTratamiento',
  components: {
    ModalCategoria,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  created() {
    this.getData();
  },
  data() {
    return {
      configUI: {
        id: 0,
        nombre: '',
      },
      isDisabled: true,
      activar: false,
      isLoadingTable: false,
      fields: [
        {
          key: 'opciones', label: '', thClass: 'py-1 pr-1', class: 'text-center', thStyle: { width: '8%' },
        },
        {
          key: 'numero', label: 'N°', class: 'text-center', thStyle: { width: '5%' },
        },
        {
          key: 'id', label: 'ID', class: 'text-center', thStyle: { width: '5%' },
        },
        {
          key: 'categoria', label: 'Categoria', class: 'text-center', thStyle: { width: '17%' },
        },
        {
          key: 'nombre', label: 'Nombre', thStyle: { width: '20%' },
        },
        {
          key: 'formulario', label: 'Formulario', thStyle: { width: '7%' },
        },
        {
          key: 'precio1', label: 'Precio 1', class: 'text-center', thStyle: { width: '8%' },
        },
        {
          key: 'precio2', label: 'Precio 2', class: 'text-center', thStyle: { width: '8%' },
        },
        {
          key: 'seguros', label: 'Seguros', class: 'text-center', thStyle: { width: '13%' },
        },
        {
          key: 'activo', label: 'Estado', class: 'text-center', thStyle: { width: '9%' },
        },
      ],
      listaTabla: [],
      listaServicios: [],
      popOverSeguro: 'Hace referencia que empresas de seguro o convenio están atendiendo ese servicio.',
      paginaActual: 1,
      porPagina: 50,
      selectedId: null,
      selectedNro: null,
    };
  },
  methods: {
    openModalCategoria() {
      this.$bvModal.show('modal-categoria');
    },
    async getData() {
      this.isDisabled = true;
      try {
        const params = {
          filter_key: '',
          page: this.paginaActual,
          state: true,
          per_page: this.porPagina,
          servicio_complementario_categoria_id: this.id,
        };
        const response = await axios.get('/clinic/servicio_complementario/servicios', {
          params,
        });
        const parametroTitulo = response.data.data.nombre_parametro_servicio_complementario;
        this.listaServicios = response.data.data.servicios;
        this.listaTabla = response.data.data.servicios_complementarios.data;
        if (parametroTitulo !== null) {
          this.configUI = parametroTitulo;
          this.activar = (parametroTitulo.estado === 1);
        }
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isDisabled = false;
      }
    },
    async saveActivarDesactivarUI() {
      this.isDisabled = true;
      try {
        const REQUEST = {
          id: this.configUI.id,
          servicio_complementario_categoria_id: this.id,
          estado: this.activar,
        };
        const RESPONSE = await axios.post('clinic/servicio_complementario/nombre_parametro_servicio_complementario', REQUEST);
        util.showNotify(RESPONSE.data.message, 'success');
        this.configUI.id = RESPONSE.data.data.parametro_servicio_complementario.id;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isDisabled = false;
      }
    },
    async guardarParametroNombre() {
      if (this.configUI.nombre === '') {
        util.showNotify('El nombre es requerido', 'warn');
        return;
      }

      this.isDisabled = true;
      try {
        const REQUEST = {
          id: this.configUI.id,
          nombre: this.configUI.nombre,
          servicio_complementario_categoria_id: this.id,
          estado: this.activar,
        };
        const RESPONSE = await axios.post('clinic/servicio_complementario/nombre_parametro_servicio_complementario', REQUEST);
        util.showNotify(RESPONSE.data.message, 'success');
        this.configUI.id = RESPONSE.data.data.parametro_servicio_complementario.id;
        this.configUI.nombre = RESPONSE.data.data.parametro_servicio_complementario.nombre;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isDisabled = false;
      }
    },
    async updateEstadoItem(item) {
      try {
        const REQUEST = {
          activo: item.activo,
        };
        const RESPONSE = await axios.put(`clinic/servicio_complementario/servicios/update/${item.id}`, REQUEST);
        util.showNotify(RESPONSE.data.message, 'success');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.getData();
      }
    },
    onClickEliminarIcon(item) {
      this.selectedId = item.id;
      this.selectedNro = item.numero;
      this.$bvModal.show('modal-eliminar');
    },
    eliminarRegistroLocalmente() {
      this.listaTabla = this.listaTabla.filter((item) => (
        item.id !== this.selectedId
      ));
    },
    async eliminarRegistro() {
      this.$bvModal.hide('modal-eliminar');
      try {
        this.eliminarRegistroLocalmente();
        const RESPONSE = await axios.delete(`/clinic/servicio_complementario/servicios/${this.selectedId}`);
        util.showNotify(RESPONSE.data.message, 'success');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
        await this.getData();
      }
    },
    getPrecio1(preciosListString) {
      const precios = JSON.parse(preciosListString);
      if (precios.length > 0) {
        return this.$options.filters.numeral(precios[0].monto, '0,0.00');
      }
      return '-';
    },
    getPrecio2(preciosListString) {
      const precios = JSON.parse(preciosListString);
      if (precios.length > 1) {
        return this.$options.filters.numeral(precios[1].monto, '0,0.00');
      }
      return '-';
    },
  },
};
</script>
<style scoped>
.text-lugar-afuera {
  background: gray;
  color: white;
}
</style>
