<template>
  <b-modal
    title="Nuevo Servicio"
    hide-footer
    id="theModalNuevoServicio">
    <div class="modal-body">
      <div class="form-group row">
        <div class="col-sm-9">
          <input type="text" class="form-control">
        </div>
        <div class="col-sm-3">
          <button class="btn btn-success">Agregar</button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'TheModalServicio',
  data() {
    return {};
  },
  methods: {},
};
</script>
