<template>
  <b-modal
    title="Establecer Edad"
    hide-footer
    id="theModalEdad"
    size="custom-lg-max"
  >
    <div class="moda body">
      <div class="form-group row">
        <div class="col-sm-9">
          <input type="text" class="form-control">
        </div>
        <div class="col-sm-3">
          <button class="btn btn-success">Agregar</button>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <div class="form-group row">
            <div class="col-sm-12 mb-2 text-center cabecera-edad">
              <label for="hombre" class="col-form-label text-center" style="font-size: 15px;">
                <strong>Hombre </strong>
              </label>
            </div>
            <!-- NEONATO -->
            <label for="neonato" class="col-sm-4 col-form-label text-right">Neonato</label>
            <div class="col-sm-4">
              <input type="text" class="form-control input">
            </div>
            <div class="col-sm-4">
              <input type="text" class="form-control input">
            </div>
            <!-- NORMAL -->
            <label for="normal" class="col-sm-4 col-form-label text-right">Normal</label>
            <div class="col-sm-4">
              <input type="text" class="form-control input">
            </div>
            <div class="col-sm-4">
              <input type="text" class="form-control input">
            </div>
            <!-- TERCERA EDAD -->
            <label for="tercera_edad"
              class="col-sm-4 col-form-label text-right"
              style="white-space: nowrap;">Tercera Edad</label>
            <div class="col-sm-4">
              <input type="text" class="form-control input">
            </div>
            <div class="col-sm-4">
              <input type="text" class="form-control input">
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group row">
            <div class="col-sm-12 mb-2 text-center cabecera-edad">
              <label for="mujer" class="col-form-label text-center" style="font-size: 15px;">
                <strong> Mujer </strong>
              </label>
            </div>
            <!-- NEONATO -->
            <div class="col-sm-4 offset-2">
              <input type="text" class="form-control input">
            </div>
            <div class="col-sm-4 mb-1">
              <input type="text" class="form-control input">
            </div>
            <!-- NORMAL -->
            <div class="col-sm-4 offset-2">
              <input type="text" class="form-control input">
            </div>
            <div class="col-sm-4 mb-1">
              <input type="text" class="form-control input">
            </div>
            <!-- TERCERA EDAD -->
            <div class="col-sm-4 offset-2">
              <input type="text" class="form-control input">
            </div>
            <div class="col-sm-4">
              <input type="text" class="form-control input">
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group row">
            <div class="col-sm-12 mb-2 text-center cabecera-edad">
              <label for="unisex" class="col-form-label text-center" style="font-size: 15px;">
                <strong> Unisex </strong>
              </label>
            </div>
            <!-- NEONATO -->
            <div class="col-sm-4 offset-2">
              <input type="text" class="form-control input">
            </div>
            <div class="col-sm-4 mb-1">
              <input type="text" class="form-control input">
            </div>
            <!-- NORMAL -->
            <div class="col-sm-4 offset-2">
              <input type="text" class="form-control input">
            </div>
            <div class="col-sm-4 mb-1">
              <input type="text" class="form-control input">
            </div>
            <!-- TERCERA EDAD -->
            <div class="col-sm-4 offset-2">
              <input type="text" class="form-control input">
            </div>
            <div class="col-sm-4">
              <input type="text" class="form-control input">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-success col-sm-2" type="button">Guardar</button>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'TheModalEdad',
  data() {
    return {};
  },
};
</script>

<style scoped>
.cabecera-edad {
  background-color: rgba(223, 223, 223, 0.815);
}
.input {
  border-radius: 10%;
  width: 130%;
}
</style>
