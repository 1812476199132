<template>
  <b-modal
  title="Titulo"
  hide-footer
  id="theModalTitulo"
  size="lg">
    <div class="modal-body">
      <div class="form-group row">
        <div class="col-sm-8">
          <input type="text" class="form-control" v-model="nombreTitulo">
        </div>
        <div class="col-sm-2">
          <button class="btn btn-success" @click="agregarItem">Agregar</button>
        </div>
      </div>
      <div class="form-group">
        <table class="table table-striped hover">
          <thead>
            <tr>
              <th width="40%" class="text-center">Nombre</th>
              <th width="40%" class="text-center">Vinculado</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(titulo, index) in listTitulos" :key="index">
              <td>
                <strong> {{ titulo.nombre  }}</strong>
              </td>
              <td>
                {{ titulo.vinculado }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { util } from '../../plugins/util';

export default {
  name: 'TheModalTitulo',
  data() {
    return {
      nombreTitulo: null,
      listTitulos: [],
    };
  },
  methods: {
    agregarItem() {
      if (!this.nombreTitulo) {
        util.showNotify('El nombre del titulo es requerido', 'warn');
        return;
      }
      this.listTitulos.push({
        id: new Date().getTime(),
        nombre: this.nombreTitulo,
        vinculado: 0,
      });
      this.nombreTitulo = null;
    },
  },
  watch: {
    nombreTitulo(new1) {
      if (new1) {
        const NOMBRE = new1.toUpperCase();
        this.nombreTitulo = NOMBRE;
      }
    },
  },
};
</script>
