<template>
  <div>
    <div class="row mb-3">
      <div class="col-sm-12">
        <div class="form-group row" style="margin: auto;">
          <label class="checkbox checkbox-success mr-4">
            <input
              id="activar-farmacia"
              type="checkbox"
              v-model="activar"
              @change="saveActivarDesactivarUI"
              :disabled="isDisabled"
            />
            <span>Activar</span>
            <span class="checkmark"></span>
          </label>
          <div class="col-sm-2">
            <b-input-group>
              <input
                v-model="configUI.nombre"
                @keyup.enter="guardarParametroNombre"
                type="text"
                class="form-control"
                :placeholder="!configUI.nombre ? 'S/N': ''"
                :disabled="isDisabled"
              >
              <b-input-group-append>
                <button
                  class="btn btn-light px-2"
                  @click="guardarParametroNombre"
                  :disabled="isDisabled"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    fill="currentColor"
                    class="bi bi-floppy"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 2H9v3h2z"/>
                    <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16
                      2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0
                      0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5
                      0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0
                      0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5
                      7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0
                      .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5
                      0 0 0-.5.5z"
                    />
                  </svg>
                </button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div class="row" style="margins: 3%;" v-if="activar">
      <div class="col-sm-12">
        <div class="form-group row" style="margin-left: 3%;">
          <label class="checkbox checkbox-success mr-4">
            <input
              type="checkbox"
              id="jalar-stock"
              v-model="jalarStock"
              @change="saveJalarStock"
              :disabled="isDisabled"
            />
            <span>Jalar Stock de Almacen</span>
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="form-group row" style="margin-left: 3%;">
          <label class="checkbox checkbox-success mr-4">
            <input
              type="checkbox"
              id="agregar-producto"
              v-model="addProductoManual"
              @change="saveAddProductoManual"
              :disabled="isDisabled"
            />
            <span>Agregar Producto Manual</span>
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
      <div class="col-sm-12">
        <b-table
          class="table table-striped table-hover"
          :fields="fields"
          :items="listaTabla"
          :busy="isLoadingTable"
        >
          <!-- <template #cell(opciones)>
            <i
              class="nav-icon i-Close-Window font-weight-bold text-danger"
              style="cursor: pointer"
            ></i>
          </template> -->
          <template #cell(estado)="row">
            <button
              class="btn py-1"
              :class="row.item.estado? 'btn-success': 'btn-danger'"
            >
              {{row.item.estado? 'Activo':'Pausa'}}
            </button>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default {
  name: 'TheFarmacia',
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      configUI: {
        id: 0,
        nombre: '',
      },
      isDisabled: true,
      activar: false,
      fields: [
        /* {
          key: 'opciones', label: '', thClass: 'py-1 pr-1', class: 'text-center', thStyle: { width: '4%' },
        }, */
        {
          key: 'numero', label: 'N°', class: 'text-center', thStyle: { width: '5%' },
        },
        {
          key: 'nombre', label: 'Nombre', class: 'text-center', thStyle: { width: '31%' },
        },
        {
          key: 'fechaCreacion', label: 'F/Creado', class: 'text-center', thStyle: { width: '15%' },
        },
        {
          key: 'name', label: 'Usuario', class: 'text-center', thStyle: { width: '27%' },
        },
        {
          key: 'cantidadUsado', label: 'Cantidad usado', class: 'text-center', thStyle: { width: '13%' },
        },
        {
          key: 'estado', label: 'Estado', class: 'text-center', thStyle: { width: '9%' },
        },
      ],
      listaTabla: [],
      isLoadingTable: false,
      jalarStock: false,
      addProductoManual: false,
      paginaActual: 1,
      porPagina: 50,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getMedicamentos() {
      try {
        const params = {
          filter_key: '',
          page: this.paginaActual,
          state: true,
          per_page: this.porPagina,
        };
        const response = await axios.get('/clinic/servicio_complementario/medicamento', {
          params,
        });
        this.listaTabla = response.data.data.medicamentos.data;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async getData() {
      this.isDisabled = true;
      try {
        const params = {
          filter_key: '',
          page: this.paginaActual,
          state: true,
          per_page: this.porPagina,
          servicio_complementario_categoria_id: this.id,
        };
        const response = await axios.get('/clinic/servicio_complementario/servicios', {
          params,
        });
        const parametroTitulo = response.data.data.nombre_parametro_servicio_complementario;
        if (parametroTitulo !== null) {
          this.configUI = parametroTitulo;
          this.activar = (parametroTitulo.estado === 1);
          this.jalarStock = (parametroTitulo.visualiza_stock === 1);
          this.addProductoManual = (parametroTitulo.agrega_producto === 1);
        }
        await this.getMedicamentos();
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isDisabled = false;
      }
    },
    async saveActivarDesactivarUI() {
      this.isDisabled = true;
      try {
        const REQUEST = {
          id: this.configUI.id,
          servicio_complementario_categoria_id: this.id,
          estado: this.activar,
        };
        const RESPONSE = await axios.post('clinic/servicio_complementario/nombre_parametro_servicio_complementario', REQUEST);
        util.showNotify(RESPONSE.data.message, 'success');
        this.configUI.id = RESPONSE.data.data.parametro_servicio_complementario.id;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isDisabled = false;
      }
    },
    async guardarParametroNombre() {
      if (this.configUI.nombre === '') {
        util.showNotify('El nombre es requerido', 'warn');
        return;
      }

      this.isDisabled = true;
      try {
        const REQUEST = {
          id: this.configUI.id,
          nombre: this.configUI.nombre,
          servicio_complementario_categoria_id: this.id,
          estado: this.activar,
        };
        const RESPONSE = await axios.post('clinic/servicio_complementario/nombre_parametro_servicio_complementario', REQUEST);
        util.showNotify(RESPONSE.data.message, 'success');
        this.configUI.id = RESPONSE.data.data.parametro_servicio_complementario.id;
        this.configUI.nombre = RESPONSE.data.data.parametro_servicio_complementario.nombre;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isDisabled = false;
      }
    },
    async saveJalarStock() {
      this.isDisabled = true;
      try {
        const REQUEST = {
          id: this.configUI.id,
          servicio_complementario_categoria_id: this.id,
          visualiza_stock: this.jalarStock,
        };
        const RESPONSE = await axios.post('clinic/servicio_complementario/nombre_parametro_servicio_complementario', REQUEST);
        util.showNotify(RESPONSE.data.message, 'success');
        this.configUI.id = RESPONSE.data.data.parametro_servicio_complementario.id;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isDisabled = false;
      }
    },
    async saveAddProductoManual() {
      this.isDisabled = true;
      try {
        const REQUEST = {
          id: this.configUI.id,
          servicio_complementario_categoria_id: this.id,
          agrega_producto: this.addProductoManual,
        };
        const RESPONSE = await axios.post('clinic/servicio_complementario/nombre_parametro_servicio_complementario', REQUEST);
        util.showNotify(RESPONSE.data.message, 'success');
        this.configUI.id = RESPONSE.data.data.parametro_servicio_complementario.id;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isDisabled = false;
      }
    },
  },
  computed: {},
};
</script>
