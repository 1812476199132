<template>
  <b-modal
    title="Indicaciones"
    hide-footer
    id="theModalIndicacion"
    size="custom-lg-max">
      <template #modal-header="{ close }">
        <div class="col-sm-12">
          <div class="form-group row">
          <div class="col-sm-7">
            <div class="col-sm-12 text-left">
              <h1 style="font-size: 20px;">
                <strong> ID: 33 - Derminitis cronica </strong>
              </h1>
            </div>
          </div>
          <div class="col-sm-4 text-right">
            <div class="form-group row">
              <label class="col-sm-3 col-form-label text-right">Metodo:</label>
              <div class="col-sm-7">
                <multiselect2
                  track-by="id"
                  label="nombre"
                  placeholder="Seleccione"
                  :options="tipMetodos"
                  multiple
                  :searchable="true">
                </multiselect2>
              </div>
            </div>
          </div>
          <div class="col-sm-1 text-right">
            <span class="font-weight-bold align-middle" @click="close()"
              style="cursor: pointer">X</span>
          </div>
        </div>
        </div>
      </template>
      <div class="modal-body">
        <div class="form-group row">
          <div class="col-sm-7">
            <input type="text" class="form-control" placeholder="Agregar nombre del analisis">
          </div>
          <div class="col-sm-5">
            <button class="btn btn-warning mr-2">Texto</button>
            <button class="btn btn-orange mr-2">Numero</button>
            <button class="btn btn-danger mr-2">Combo</button>
          </div>
        </div>
        <div class="row">
          <table class="table table-striped hover">
            <thead>
              <tr>
                <th width="5%" class="text-center">ID</th>
                <th width="20%" class="text-center"></th>
                <th width="47%" class="text-center"></th>
                <th width="23%" class="text-center"></th>
                <th width="5%" class="text-center"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(indicacion, index) in listaIndicaciones" :key="index">
                <td>{{ index+1 }}
                  <tr>
                    <i
                      class="nav-icon i-Close-Window font-weight-bold text-danger"
                      style="cursor: pointer"
                      title="eliminar"
                      ></i>
                  </tr>
                </td>
                <td>
                  <strong> {{ indicacion.nombre }} </strong>
                  <tr>
                    <span>Neonoatal</span>
                  </tr>
                </td>
                <!-- TITULO -->
                <td>
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group row">
                        <div class="col-sm-6 text-right">
                          <strong>Titulo</strong>
                        </div>
                        <div class="col-sm-5 text-left">
                          <multiselect2
                            track-by="id"
                            label="nombre"
                            placeholder="Seleccione"
                            :options="[]"
                            multiple
                            :searchable="true">
                          </multiselect2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <tr> -->
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="form-group row">
                          <div class="col-sm-3">
                            <input type="text" disabled class="form-control"
                            placeholder="H. Rango">
                          </div>
                          <div class="col-sm-3" style="right: 3%;">
                            <input type="text" disabled class="form-control"
                            placeholder="H. Rango">
                          </div>
                          <div class="col-sm-3" style="right: 5%;">
                            <input type="text" class="form-control"
                            placeholder="M. Rango">
                          </div>
                          <div class="col-sm-3" style="right: 8%;">
                            <input type="text" class="form-control"
                            placeholder="M. Rango">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="form-group row">
                          <div class="col-sm-6">
                            <input type="text" class="form-control">
                          </div>
                          <div class="col-sm-6">
                            <button class="btn btn-success">Agregar</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  <!-- </tr> -->
                </td>
                <!-- RESULTADO NUMERO -->
                <td>
                  <div class="form-group row">
                    <label class="col-sm-6 col-form-label text-right"
                    style="white-space: nowrap;">
                    <strong> Resultado Numero: </strong>
                  </label>
                    <div class="col-sm-6">
                      <vue-numeric class="form-control"
                          v-model="indicacion.numero"
                          separator=","
                          :precision="2"
                          placeholder=""
                        />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group row" v-if="indicacion.tipo_id === 1">
                        <div class="col-sm-6">
                          <multiselect2
                            v-model="indicacion.tipo"
                            track-by="id"
                            label="nombre"
                            placeholder="Sigla"
                            :options="[]"
                            multiple
                            :searchable="true">
                          </multiselect2>
                        </div>
                        <div class="col-sm-6">
                          <multiselect2
                            v-model="indicacion.tipo"
                            track-by="id"
                            label="nombre"
                            placeholder="Metodo"
                            :options="[]"
                            multiple
                            :searchable="true">
                          </multiselect2>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <i
                  class="nav-icon i-Close-Window font-weight-bold text-danger"
                  style="cursor: pointer"
                  title="eliminar"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <div class="col-sm-10">
          <input type="text" class="form-control"
            placeholder="Requisitos para hacer este tipo de analisis">
        </div>
        <div class="col-sm-2 text-right">
          <button class="btn btn-success" type="button"> Guardar</button>
        </div>
      </div>
  </b-modal>
</template>

<script>
import VueNumeric from 'vue-numeric';
import Multiselect2 from '@/components/util/vue-multiselect2';

export default {
  name: 'TheModalIndicacion',
  data() {
    return {
      listaIndicaciones: [
        {
          id: 1,
          nombre: 'Dermatologico',
          tipo_id: 1,
          tipo: 0,
        },
      ],
      tipos: [
        {
          id: 1,
          nombre: 'Positivo',
        },
        {
          id: 2,
          nombre: 'Negativo',
        },
      ],
      tipMetodos: [
        {
          id: 1,
          nombre: 'Grupal',
        },
        {
          id: 2,
          nombre: 'Individual',
        },
      ],
    };
  },
  components: {
    Multiselect2,
    VueNumeric,
  },
};
</script>

<style scoped>
.btn-orange {
  background-color: #f4B375
}
.label-texto {
  font-size: 11px;
  margin: auto;
  padding: inherit;
}
</style>
