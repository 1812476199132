<template>
  <div>
    <div class="row mb-3">
      <div class="col-sm-12">
        <div class="form-group row" style="margin: auto;">
          <label class="checkbox checkbox-success mr-4">
            <input
              type="checkbox"
              id="activar-farmacia"
              v-model="activar"
              @change="saveActivarDesactivarUI"
              :disabled="isDisabled"
            />
            <span>Activar</span>
            <span class="checkmark"></span>
          </label>
          <div class="col-sm-2">
            <b-input-group>
              <input
                v-model="configUI.nombre"
                @keyup.enter="guardarParametroNombre"
                type="text"
                class="form-control"
                :placeholder="!configUI.nombre ? 'S/N': ''"
                :disabled="isDisabled"
              >
              <b-input-group-append>
                <button
                  class="btn btn-light px-2"
                  @click="guardarParametroNombre"
                  :disabled="isDisabled"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    fill="currentColor"
                    class="bi bi-floppy"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 2H9v3h2z"/>
                    <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16
                      2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0
                      0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5
                      0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0
                      0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5
                      7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0
                      .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5
                      0 0 0-.5.5z"
                    />
                  </svg>
                </button>
              </b-input-group-append>
            </b-input-group>
          </div>
         <div class="col-sm-1">
          <button type="button"
            class="btn btn-outline-secondary btn-sm btn-block"
            @click="changeDesignOfTable" :disabled="!activar"
            :style="{ backgroundColor: activar ? '' : '#f5f5f5', color: activar ? '' : '#333', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }">
            {{ buttonText }}
          </button>
          </div>
          <div class="col-sm-1.5">
          <button type="button"
            class="btn btn-outline-secondary btn-sm btn-block"
            @click="changeDesignOfTable" :disabled="!activar"
            :style="{ backgroundColor: activar ? '' : '#f5f5f5', color: activar ? '' : '#333', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }">
            Crear Muestra
          </button>
          </div>
          <div class="col-sm-1">
          <button type="button"
            class="btn btn-outline-secondary btn-sm btn-block"
            @click="changeDesignOfTable" :disabled="!activar"
            :style="{ backgroundColor: activar ? '' : '#f5f5f5', color: activar ? '' : '#333', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }">
            Ver Modal
          </button>
          </div>
           <div class="offset-sm-3 col-sm-2 px-0" v-show="design === 1 && activar">
               <select class="custom-select" id="data_source_single">
                <option value="null" ></option>
            </select>
           </div>
           <div class="col-sm-1" v-show="design === 1 && activar">
            <button
                class="btn btn-success btn-block"
              >Agregar</button>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div v-if="design === 1">
      <div class="row" v-show="activar">
        <div class="col-sm-12">
          <b-table
          class="table table-striped table-hover"
          :fields="fieldsLaboratorio"
          :items="listSecundTable"
          >
          <template #head(opciones)>
            <button
              class="btn btn-block btn-success"
            >Nuevo</button>
            </template>
            <template #head(seguros)="head">
            <div v-b-popover.hover.top="popOverSeguro">
              {{head.label}}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-exclamation-circle-fill text-danger"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 4a.905.905 0 0 0-.9.995l.35
                 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4m.002 6a1 1 0 1 0
                 0 2 1 1 0 0 0 0-2"
                />
              </svg>
            </div>
            </template>
              <template #head(lugar)="head">
            <div v-b-popover.hover.top="popOverLugar">
              {{head.label}}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-exclamation-circle-fill text-danger"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 4a.905.905 0 0 0-.9.995l.35
                 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4m.002 6a1 1 0 1 0
                 0 2 1 1 0 0 0 0-2"
                />
              </svg>
            </div>
            </template>
             <template #cell(opciones)="items">
            <!-- <i
              class="nav-icon i-Pen-5 font-weight-bold text-success pr-3"
              style="cursor: pointer"
            ></i> -->
            <div class="row">
              <div class="col">
                <i
                @click="onClickEliminarIcon(items.item)"
                class="nav-icon i-Close-Window font-weight-bold text-danger"
                style="cursor: pointer"
              ></i>
              </div>
              <div class="col">
                <i
                  class="nav-icon i-Pen-5 font-weight-bold text-warning"
                  style="cursor: pointer"
                ></i>
              </div>
            </div>
              </template>
            <template #table-busy>
            <div class="text-center text-danger my-2">
              <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
              <strong>Cargando...</strong>
            </div>
            </template>
             <template #cell(activo)="row">
            <button
              class="btn py-1"
              :class="row.item.activo? 'btn-success': 'btn-danger'"
              @click="row.item.activo = !row.item.activo;updateEstadoItem(row.item)"
            >
              {{row.item.activo? 'Activo':'Pausa'}}
            </button>
          </template>
          </b-table>
        </div>
      </div>
    </div>
    <div v-else>
       <div class="row" style="margin: 3%;" v-show="activar">
        <div class="col-sm-12" style="border: 1px solid #dd9e2b; padding: 1%;">
          <div class="form-group row">
            <div class="col-sm-10">
              <button class="btn btn-seconday mr-2" type="button"
                @click="openModalServicio"
              >
              Nuevo
              </button>
              <button class="btn btn-seconday mr-2" type="button"
                @click="openModalArea">
                Area
              </button>
              <button class="btn btn-seconday mr-2"
                type="button" @click="openModalEdad">Edad</button>
              <button class="btn btn-seconday mr-2" type="button"
                @click="openModalMuestra">Muestra</button>
              <button class="btn btn-seconday mr-2" type="button"
                @click="openModalMetodo">Metodos</button>
              <button class="btn btn-seconday mr-2" type="button"
                @click="openModalTitulo">Titulo</button>
              <button class="btn btn-seconday mr-2" type="button"
                @click="openModalSigla">Sigla</button>
              <button class="btn btn-seconday mr-2" type="button">Categoria</button>
            </div>
            <div class="col-sm-2">
              <multiselect2
                v-model="accionSelected"
                track-by="id"
                label="nombre"
                placeholder="Accion"
                :options="acciones"
                :allow-empty="false"
                :searchable="true"
              ></multiselect2>
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <b-table
            class="table table-striped table-hover"
            :fields="fields"
            :items="listarLaboratorio">
            <template #cell(estado)="items">
              <button class="btn"
              :class="items.item.estado === 1 ? 'btn-success': 'btn-warning'">
                {{ items.item.estado === 1 ? 'Activo' : 'Pausa' }}
              </button>
            </template>
            <template #cell(indicacion)>
              <button class="btn btn-danger" @click="openModalIndicacion">
                Ver
              </button>
            </template>
            <template #cell(area_id)="items">
              <multiselect2
                v-model="items.item.area_selected"
                track-by="id"
                label="nombre"
                placeholder="Accion"
                :options="tipoAreas"
                :allow-empty="false"
                :searchable="true"
              ></multiselect2>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <TheModalAreaLaboratorio/>
    <TheModalServicio/>
    <TheModalEdad/>
    <TheModalIndicacion/>
    <TheModalSigla/>
    <TheModalTitulo/>
    <TheModalMuestra/>
    <TheModalMetodo/>
  </div>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import Multiselect2 from '@/components/util/vue-multiselect2';
import TheModalServicio from '@/components/ParametroServicioComplementario/TheModalServicio.vue';
import TheModalAreaLaboratorio from '@/components/ParametroServicioComplementario/TheModalAreaLaboratorio.vue';
import TheModalEdad from '@/components/ParametroServicioComplementario/TheModalEdad.vue';
import TheModalIndicacion from '@/components/ParametroServicioComplementario/TheModalIndicacion.vue';
import TheModalSigla from '@/components/ParametroServicioComplementario/TheModalSigla.vue';
import TheModalTitulo from '@/components/ParametroServicioComplementario/TheModalTitulo.vue';
import TheModalMuestra from '@/components/ParametroServicioComplementario/TheModalMuestra.vue';
import TheModalMetodo from '@/components/ParametroServicioComplementario/TheModalMetodo.vue';

export default {
  name: 'TheLaboratorio',
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      activar: false,
      configUI: {
        id: 0,
        nombre: '',
      },
      isDisabled: true,
      accionSelected: null,
      design: 1,
      acciones: [
        {
          id: 1,
          nombre: 'Agregar',
        },
        {
          id: 2,
          nombre: 'Actualizar',
        },
        {
          id: 3,
          nombre: 'Separar',
        },
      ],
      listarLaboratorio: [
        {
          id: 1,
          numero: 1,
          categoria: 'Laboratorio',
          nombre: 'ID:34 - Analisis de Sangre 4P',
          area_id: 1,
          importe: '900.00 Bs',
          estado: 1,
          area_selected: {
            id: 1,
            nombre: 'Cerologia',
          },
        },
        {
          id: 2,
          numero: 2,
          categoria: 'Laboratorio 2',
          nombre: 'ID:35 - Analisis de Sangre',
          area_id: 1,
          importe: '900.00 Bs',
          estado: 2,
          area_selected: null,
        },
      ],
      fields: [
        {
          key: 'numero', label: 'N°', class: 'text-center', thStyle: { width: '10%' },
        },
        {
          key: 'categoria', label: 'Categoria TS', class: 'text-center', thStyle: { width: '20%' },
        },
        {
          key: 'nombre', label: 'Nombre', class: 'text-center', thStyle: { width: '20%' },
        },
        {
          key: 'area_id', label: 'Area', class: 'text-center', thStyle: { width: '10%' },
        },
        {
          key: 'importe', label: 'Importe', class: 'text-center', thStyle: { width: '10%' },
        },
        {
          key: 'indicacion', label: 'Indicacion', class: 'text-center', thStyle: { width: '10%' },
        },
        {
          key: 'estado', label: 'Estado', class: 'text-center', thStyle: { width: '10%' },
        },
        {
          key: 'estado', label: 'Estado', class: 'text-center', thStyle: { width: '10%' },
        },
        {
          key: 'eliminar', label: ' ', class: 'text-center', thStyle: { width: '3%' },
        },
      ],
      tipoAreas: [
        {
          id: 1,
          nombre: 'Cerologia',
        },
        {
          id: 2,
          nombre: 'Opcion 2',
        },
        {
          id: 3,
          nombre: 'Opcion 3',
        },
      ],
      tipoAreaSelected: null,
      paginaActual: 1,
      porPagina: 50,
      fieldsLaboratorio: [
        {
          key: 'opciones', label: '', thClass: 'py-1 pr-1', class: 'text-center', thStyle: { width: '8%' },
        },
        {
          key: 'numero', label: 'N°', class: 'text-center', thStyle: { width: '5%' },
        },
        {
          key: 'id', label: 'ID', class: 'text-center', thStyle: { width: '5%' },
        },
        {
          key: 'categoria', label: 'Categoria', class: 'text-center', thStyle: { width: '15%' },
        },
        {
          key: 'nombre', label: 'Nombre', thStyle: { width: '20%' },
        },
        {
          key: 'precio1', label: 'Precio 1', class: 'text-center', thStyle: { width: '8%' },
        },
        {
          key: 'muestra', label: 'Muestra', class: 'text-center', thStyle: { width: '8%' },
        },
        {
          key: 'ubicacion', label: 'Ubicacion', class: 'text-center', thStyle: { width: '13%' },
        },
        {
          key: 'lugar', label: 'Lugar', tdClass: 'px-0', class: 'text-center', thStyle: { width: '9%' },
        },
        {
          key: 'activo', label: 'Estado', class: 'text-center', thStyle: { width: '9%' },
        },
      ],
      popOverSeguro: 'Hace referencia que empresas de seguro o convenio están atendiendo ese servicio.',
      popOverLugar: 'Hace referencia en donde se atiende, si es la clínica se envía afuera con otra clínica.',
      listSecundTable: [
        {
          opciones: '',
          numero: 16,
          id: 6,
          categoria: 'General',
          nombre: 'Consulta medica',
          precio1: '200 Bs',
          muestra: '',
          ubicacion: 'A-4 de 10',
          lugar: '',
          activo: 'Activo',
        },
      ],
      selectedId: null,
      selectedNro: null,
    };
  },
  created() {
    this.getData();
  },
  components: {
    Multiselect2,
    TheModalAreaLaboratorio,
    TheModalServicio,
    TheModalEdad,
    TheModalIndicacion,
    TheModalSigla,
    TheModalTitulo,
    TheModalMuestra,
    TheModalMetodo,
  },
  methods: {
    async getData() {
      this.isDisabled = true;
      try {
        const params = {
          filter_key: '',
          page: this.paginaActual,
          state: true,
          per_page: this.porPagina,
          servicio_complementario_categoria_id: this.id,
        };
        const response = await axios.get('/clinic/servicio_complementario/servicios', {
          params,
        });
        const parametroTitulo = response.data.data.nombre_parametro_servicio_complementario;
        if (parametroTitulo !== null) {
          this.configUI = parametroTitulo;
          this.activar = (parametroTitulo.estado === 1);
        }
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isDisabled = false;
      }
    },
    openModalArea() {
      this.$bvModal.show('theModalAreaLaboratorio');
    },
    openModalServicio() {
      this.$bvModal.show('theModalNuevoServicio');
    },
    openModalEdad() {
      this.$bvModal.show('theModalEdad');
    },
    openModalIndicacion() {
      this.$bvModal.show('theModalIndicacion');
    },
    openModalSigla() {
      this.$bvModal.show('theModalSigla');
    },
    openModalTitulo() {
      this.$bvModal.show('theModalTitulo');
    },
    openModalMuestra() {
      this.$bvModal.show('theModalMuestra');
    },
    openModalMetodo() {
      this.$bvModal.show('theModalMetodo');
    },
    async guardarParametroNombre() {
      if (this.configUI.nombre === '') {
        util.showNotify('El nombre es requerido', 'warn');
        return;
      }

      this.isDisabled = true;
      try {
        const REQUEST = {
          id: this.configUI.id,
          nombre: this.configUI.nombre,
          servicio_complementario_categoria_id: this.id,
          estado: this.activar,
          visualiza_stock: false,
          agrega_producto: false,
        };
        const RESPONSE = await axios.post('clinic/servicio_complementario/nombre_parametro_servicio_complementario', REQUEST);
        util.showNotify(RESPONSE.data.message, 'success');
        this.configUI.id = RESPONSE.data.data.parametro_servicio_complementario.id;
        this.configUI.nombre = RESPONSE.data.data.parametro_servicio_complementario.nombre;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isDisabled = false;
      }
    },
    async saveActivarDesactivarUI() {
      this.isDisabled = true;
      try {
        const REQUEST = {
          id: this.configUI.id,
          servicio_complementario_categoria_id: this.id,
          estado: this.activar,
        };
        const RESPONSE = await axios.post('clinic/servicio_complementario/nombre_parametro_servicio_complementario', REQUEST);
        util.showNotify(RESPONSE.data.message, 'success');
        this.configUI.id = RESPONSE.data.data.parametro_servicio_complementario.id;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isDisabled = false;
      }
    },
    changeDesignOfTable() {
      this.design = 1; /* this.design === 1 ? 2 : 1; */
    },
  },
  computed: {
    buttonText() {
      return this.design === 1 ? 'Diseño 1' : 'Diseño 2';
    },
  },
  watch: {
    listarLaboratorio(new1) {
      console.log('newz', new1);
    },
  },
};
</script>
