<template>
  <b-modal
  title="Metodo"
  hide-footer
  id="theModalMetodo"
  size="lg">
    <div class="modal-body">
      <div class="form-group row">
        <div class="col-sm-4">
          <input type="text" class="form-control"
            placeholder="Agregar un Nombre" v-model="nombre">
        </div>
        <div class="col-sm-6">
          <input type="text" class="form-control" placeholder="Descripcion"
            v-model="descripcion" required>
        </div>
        <div class="col-sm-2">
          <button class="btn btn-success" @click="agregarItem">Agregar</button>
        </div>
      </div>
      <div class="form-group">
        <table class="table table-striped hover">
          <thead>
            <tr>
              <th width="40%" class="text-center">Nombre</th>
              <th width="40%" class="text-center">Descripcion</th>
              <th width="40%" class="text-center">Vinculado</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(metodo, index) in listaMetodos" :key="index">
              <td> {{ metodo.nombre }} </td>
              <td> {{ metodo.descripcion }} </td>
              <td> {{ metodo.vinculado }} </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { util } from '../../plugins/util';

export default {
  name: 'TheModalMetodo',
  data() {
    return {
      nombre: null,
      descripcion: null,
      listaMetodos: [],
    };
  },
  methods: {
    agregarItem() {
      if (!this.nombre) {
        util.showNotify('El nombre es requerido', 'warn');
        return;
      }
      this.listaMetodos.push({
        id: new Date().getTime(),
        nombre: this.nombre,
        descipcion: this.descripcion,
        vinculado: 0,
      });
      this.clear();
    },
    clear() {
      this.nombre = null;
      this.descripcion = null;
    },
  },
};
</script>
