<template>
  <b-modal
  title="Area de Laboratorio"
  hide-footer
  id="theModalAreaLaboratorio"
  size="md">
  <form>
    <div class="modal-body">
      <div class="form-group row">
        <div class="col-sm-9">
          <input type="text" class="form-control">
        </div>
        <div class="col-sm-3">
          <button class="btn btn-success">Agregar</button>
        </div>
      </div>
      <div class="row">
        <table class="table table-striped hover">
          <thead>
            <tr>
              <th width="10%" class="text-center">ID</th>
              <th width="20%" class="text-center">Nombre</th>
              <th width="20%" class="text-center">Vinculado</th>
              <th width="10%" class="text-center"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(listaAreaLaboratorio, index) in listaAreaLaboratorios" :key="index">
              <td> {{ listaAreaLaboratorio.id }} </td>
              <td> {{ listaAreaLaboratorio.nombre }} </td>
              <td> {{ listaAreaLaboratorio.vinculado }} </td>
              <td>
                <i
                  class="nav-icon i-Close-Window font-weight-bold text-danger"
                  style="cursor: pointer"
                  title="eliminar"
                  ></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </form>
  </b-modal>
</template>

<script>
export default {
  name: 'TheModalAreaLaboratorio',
  data() {
    return {
      listaAreaLaboratorios: [
        {
          id: 1,
          nombre: 'Dermatologia',
          vinculado: 0,
        },
      ],
    };
  },
};
</script>
