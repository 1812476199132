<template>
  <b-modal
  title="Muestra"
  hide-footer
  id="theModalMuestra"
  size="lg">
    <div class="modal-body">
      <div class="form-group row">
        <div class="col-sm-8">
          <input type="text" class="form-control" v-model="nombre">
        </div>
        <div class="col-sm-2">
          <button class="btn btn-success" @click="agregarItem">Agregar</button>
        </div>
      </div>
      <div class="form-group">
        <table class="table table-striped hover">
          <thead>
            <tr>
              <th width="40%" class="text-center">Nombre</th>
              <th width="40%" class="text-center">Vinculado</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(muestra, index) in listaMuestas" :key="index">
              <td> {{ muestra.nombre }} </td>
              <td> {{ muestra.vinculado }} </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { util } from '../../plugins/util';

export default {
  name: 'TheModalMuestra',
  data() {
    return {
      nombre: null,
      listaMuestas: [],
    };
  },
  methods: {
    agregarItem() {
      if (!this.nombre) {
        util.showNotify('El nombre es requerido', 'warn');
        return;
      }
      this.listaMuestas.push({
        id: new Date().getTime(),
        nombre: this.nombre,
        vinculado: 0,
      });
      this.nombre = null;
    },
  },
};
</script>
