<template>
  <b-modal
    id="modal-categoria-imageonologia"
    size="md"
    :title="titleModal"
    hide-footer
  >
  <div class="row">
    <div class="col-sm-9">
      <multiselect
        id="comboServicios"
        v-model="servicioSelected"
        :options="listaServicios"
        placeholder=""
        track-by="id"
        label="nombre"
        select-label=""
        deselect-label="X"
      >
        <span slot="caret" v-if="!(servicioSelected===null)"></span>
        <span slot="noResult">Sin Resultados</span>
        <span slot="noOptions">Lista Vacia</span>
      </multiselect>
    </div>
    <div class="col-sm-3">
      <button
        class="btn btn-success"
        @click="addServicio"
        :disabled="isDisabledButton"
      >
        Agregar
      </button>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-sm-12">
      <b>Categoria:</b> {{getCategoriaServicioSelected}}
    </div>
  </div>
  </b-modal>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import Multiselect from 'vue-multiselect';

export default {
  name: 'modalCategoriaImageonologia',
  components: {
    Multiselect,
  },
  props: {
    idParent: {
      type: [Number, String],
      required: true,
    },
    titleModal: {
      type: String,
      required: true,
    },
    listaServicios: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      servicioSelected: null,
      isDisabledButton: false,
    };
  },
  methods: {
    hideModalCategoria() {
      this.$bvModal.hide('modal-categoria-imageonologia');
    },
    async addServicio() {
      if (this.servicioSelected === null) {
        util.showNotify('Seleccione un servicio', 'warn');
        return;
      }

      this.isDisabledButton = true;
      try {
        const REQUEST = {
          tipo_servicio_id: this.servicioSelected.id,
          servicio_complementario_categoria_id: this.idParent,
          seguros: '',
          lugar: '',
        };
        const RESPONSE = await axios.post('clinic/servicio_complementario/servicios/store', REQUEST);
        util.showNotify(RESPONSE.data.message, 'success');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isDisabledButton = false;
      }

      this.$emit('onStoreService', true);
      this.hideModalCategoria();
    },
  },
  computed: {
    getCategoriaServicioSelected() {
      if (this.servicioSelected !== null) {
        return this.servicioSelected.nombre_categoria;
      }
      return '';
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
