<template>
  <b-modal
  title="Sigla"
  hide-footer
  id="theModalSigla"
  size="lg">
    <div class="modal-body">
      <div class="form-group row">
        <div class="col-sm-4">
          <input type="text" class="form-control" placeholder="Agrega un Nombre"
            style="color: #47404F;" v-model="nombre">
        </div>
        <div class="col-sm-6">
          <input type="text" class="form-control" placeholder="Complemento"
          v-model="complemento"
            style="color: gray;">
        </div>
        <div class="col-sm-2">
          <button class="btn btn-success" @click="agregarItem">Agregar</button>
        </div>
      </div>
      <div class="form-group">
        <table class="table table-striped hover">
          <thead>
            <tr>
              <th width="40%" class="text-center">Nombre</th>
              <th width="40%" class="text-center">Descripcion</th>
              <th width="40%" class="text-center">Vinculado</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(sigla, index) in listaSiglas" :key="index">
              <td> {{ sigla.nombre }} </td>
              <td> {{ sigla.complemento }} </td>
              <td> {{ sigla.vinculado }} </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { util } from '../../plugins/util';

export default {
  name: 'TheModalSigla',
  data() {
    return {
      nombre: null,
      complemento: null,
      listaSiglas: [],
    };
  },
  methods: {
    agregarItem() {
      if (this.validar()) {
        this.listaSiglas.push({
          id: new Date().getTime(),
          nombre: this.nombre,
          complemento: this.complemento,
          vinculado: 0,
        });
        this.clear();
      }
    },
    clear() {
      this.nombre = null;
      this.complemento = null;
    },
    validar() {
      let valido = true;
      if (!this.nombre) {
        valido = false;
        util.showNotify('El nombre es requerido', 'warn');
      }
      if (!this.complemento) {
        valido = false;
        util.showNotify('El nombre del complemento es requerido', 'warn');
      }
      return valido;
    },
  },
};
</script>
